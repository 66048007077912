.con-heading-schedule {
  margin: 10px 0;
  color: $link;
  font-weight: 700;

  &__xs {
    color: $link;
    font-size: 14px;

    &--bold {
      font-weight: bold;
      margin: 0 0 5px 0;
      font-size: 14px;
      color: $link;
    }

    &--development {
      font-weight: bold;
      margin: 0 0 5px 0;
      font-size: 14px;
      color: $development;
    }

    &--security {
      font-weight: bold;
      margin: 0 0 5px 0;
      font-size: 14px;
      color: $security;
    }
  }
}