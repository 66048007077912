.con-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  margin-top: auto;
  background-color: #2c2e2d;
  color: white;

  &__top-row {
    display: flex;
    justify-content: space-between;
  }

  &__link {
    margin-top: 8px;
  }
}

.con-social-media__icons {
  margin-left: 10px;
}

.con-social-media {
  color: white;
  text-align: right;
  font-size: 1rem;
}
