.con-text {
  font-size: 19px;
  color: $text-color;
  line-height: 1.4;
  white-space: pre-line;

  &__s {
    color: $text-color;
    font-size: 16px;
    line-height: 1.4;

    &--centered {
      color: $text-color;
      font-size: 16px;
      text-align: center;
      line-height: 1.4;
    }

    &--margin-bottom-1 {
      margin: 0 0 10px 0;
      font-size: 16px;
      color: $text-color;
      line-height: 1.4;
    }

    &--margin-bottom-2 {
      margin: 0 0 15px 0;
      font-size: 16px;
      color: $text-color;
      line-height: 1.4;

      &--bold {
        font-weight: bold;
        font-size: 16px;
        color: $text-color;
      }
    }

    &--margin-bottom-3 {
      margin: 0 0 70px 0;
      font-size: 16px;
      color: $text-color;
      line-height: 1.4;

      &--bold {
        font-weight: bold;
        font-size: 16px;
        color: $text-color;
      }
    }
  }

  &__xs {
    color: $text-color;
    font-size: 14px;

    &--centered {
      color: $text-color;
      font-size: 14px;
      text-align: center;
    }

    &--bold {
      font-weight: bold;
      font-size: 14px;
      color: $text-color;
    }

    &--margin-bottom-1 {
      margin: 0 0 10px 0;
      font-size: 14px;
      color: $text-color;

      &--bold {
        font-weight: bold;
        margin: 0 0 10px 0;
        font-size: 14px;
        color: $text-color;
      }
    }

    &--margin-bottom-2 {
      margin: 0 0 15px 0;
      font-size: 14px;
      color: $text-color;
      line-height: 1.4;
    }
  }

  &--margin-bottom-1 {
    margin: 0 0 10px 0;
    font-size: 19px;
    color: $text-color;
  }

  &--margin-bottom-2 {
    margin: 0 0 15px 0;
    font-size: 19px;
    color: $text-color;
    line-height: 1.4;

    &--bold {
      font-size:19px;
      font-weight: bold;
      color: $text-color;
    }
  }

  &--margin-bottom-3 {
    margin: 0 0 15px 0;
    font-size: 19px;
    color: $text-color;
  }

  &__link {
    margin-left: 15px;
    cursor: pointer;

    &:focus,
    &:hover {
      outline: 2px solid $outline-color;
      outline-offset: 2px;
      border-radius: 2px;
    }

    &--dropdown {
      background-image: url('../assets/images/dropdown.png');
      background-repeat: no-repeat;
      background-position: right;
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-left: 5px;

      &:hover {
        filter: brightness(0%);
      }
    }
  }

  &__link--underlined {
    text-decoration: underline;
  }
}

.con-heading {
  margin: 10px 0;
  color: $text-color;
  font-weight: 700;

  &__s {
    margin: 5px 0;
    font-size: 19px;
    color: $text-color;
    font-weight: 700;

    &--centered {
      margin: 5px 0;
      font-size: 19px;
      text-align: center;
      color: $text-color;
      font-weight: 700;
    }

    &--margin-bottom-1 {
      margin: 0 0 15px 0;
      font-size: 19px;
      color: $text-color;
      font-weight: 700;
    }

    &--margin-top-1 {
      margin: 30px 0 10px 0;
      font-size: 19px;
      color: $text-color;
      font-weight: 700;
    }
  }

  &__m {
    margin: 5px 0;
    font-size: 22px;
    color: $text-color;
    font-weight: 700;

    &--centered {
      margin: 5px 0;
      font-size: 22px;
      text-align: center;
      color: $text-color;
      font-weight: 700;

      &--margin-bottom-0 {
        margin: 0;
        font-size: 19px;
        color: $text-color;
        text-align: center;
        font-weight: 700;
      }

      &--margin-bottom-1 {
        margin: 0 0 10px 0;
        font-size: 19px;
        color: $text-color;
        text-align: center;
        font-weight: 700;
      }

      &--margin-bottom-2 {
        margin: 0 0 15px 0;
        font-size: 19px;
        color: $text-color;
        text-align: center;
        font-weight: 700;
      }

      &--margin-bottom-3 {
        margin: 0 0 20px 0;
        font-size: 19px;
        color: $text-color;
        text-align: center;
        font-weight: 700;
      }
    }

    &--margin-bottom-0 {
      margin: 0;
      font-size: 19px;
      color: $text-color;
    }

    &--margin-bottom-1 {
      margin: 0 0 10px 0;
      font-size: 19px;
      color: $text-color;
    }

    &--margin-bottom-2 {
      margin: 0 0 15px 0;
      font-size: 19px;
      color: $text-color;
    }

    &--margin-bottom-3 {
      margin: 0 0 20px 0;
      font-size: 19px;
      color: $text-color;
      font-weight: 700;
    }
  }

  &__l {
    margin: 10px 0;
    font-size: 22px;
    color: $text-color;

    &--centered {
      margin: 10px 0;
      font-size: 22px;
      text-align: center;
      color: $text-color;
    }

    &--margin-bottom-0 {
      margin: 0;
      font-size: 22px;
      color: $text-color;
    }

    &--margin-bottom-3 {
      margin: 0 0 20px 0;
      font-size: 22px;
      color: $text-color;
    }
  }

  &__main {
    border-radius: 3px;

    &:focus {
      outline: 2px solid $outline-color;
      outline-offset: -2px;
    }
  }
}

.con-basic-styling {
  white-space: pre-line;

  &-bold {
    font-weight: 700;
    display: inline-block;

    &-italic {
      font-style: italic;
      font-weight: 700;
      display: inline-block;
    }
  }

  &-italic {
    font-style: italic;
    display: inline-block;
  }
}

.con-wrap {
  word-break: break-all;
}

.con-subheading {
  color: $text-color;
}

.con-bold {
  font-weight: bold;
}