.dragon-image-container {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
}

.dragon-image-container img {
    width: auto;
    height: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    align-items: flex-start;
}

.dragon-image-label-container {
    display: flex;
    flex-direction: column;
    width: 33.3%;
}

.dragon-image-label {
    text-align: center;
}

.emcee-banner {
    background-color: #273778;
    display: flex;
    width: 100%;
    align-items: center;
}

.emcee-container {
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1vh;
    height: 100%;
}

.emcee-image {
    height: 25vh;
    width: auto;
    object-fit: contain;
}

.emcee-text {
    flex: 50%;
    color: white;
    text-align: center;
    margin: 0 5%;
}

.emcee-subheader {
    flex: 50%;
    color: #c5adc3;
    text-align: center;
    margin: 0 5%;
    margin-bottom: 1vh;
}

@media (max-width: 768px) {
    .dragon-image-container {
      flex-direction: column;
      align-items: center;
    }
    .emcee-banner {
        flex-direction: column;
        align-items: center;
      }
    .dragon-image-label-container {
        width: 75%;
        height: auto;
    }
    .dragon-image-label-container img {
        width: 100%;
        height: auto; 
        object-fit: cover;
    }
} 