@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

// reset values and vars
@import './baseStyles.scss';
@import './variables.scss';

// common/global styles
@import './app.scss';
@import './typography.scss';

// component imports

@import '../components/footer';
@import '@kainos-applied-innovation/convention-frontend-components/dist';

@import '../components/pages/homePage';
@import '../components/pages/dragonsDenPage';
@import '../components/pages/eduConferencePage';
@import '../components/pages/registerPage/';
@import '../components/pages/schedulePage/';
@import '../components/pages/venuePage/'
