$white: #fff;
$border: #aaa;
$link: #268977;
$background: #f1f2f3;
$text-color: #676767;
$black: #0b0b0c;
$outline-color: #66C4B1;
$button-color: #2c2e2d;
$error: #b22;
$lilac-background: #EBF5F4;
$development: #d33192;
$security: #01983E;
$button-background: #2a3384;