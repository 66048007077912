.con-register__button {
    color: $white;
    border-radius: 14px;
    background-color: #5e9ed6;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40%;
    margin-right: 40%;
    padding: 5px 15px;
}

.con-button__wrap {
    padding-top: 10px;
}

.parent-grid {
    display: flex;
    flex-direction: row;
}

.left-box {
    grid-column: 1;
    float: left;
    justify-content: flex-start;
    width: 5;
    text-align: center;
}

.rightbox {
    width: 50%;
    float:right;
}

.headertext {

  display: flex;
  justify-content: center;
  align-items: center;
}

/* Phone screen styling */
@media screen and (max-width: 768px) {
    .parent-grid {
        flex-direction: column; 
    }
}

.ticket-name {
    color: #5e9ed6;
}