.con-dragons-den__button {
    width: 200px;
    margin: 50px 0;
    padding: 5px 15px;
    color: $white;
    border-radius: 14px;
    background-color: $button-color;
    cursor: pointer;
}

.con-button__wrap {
    padding-top: 50px;
    padding-bottom: 50px;
}

.con-techstart-logo {
    display: flex;
    justify-content: center;
    width: 50%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.text-bar {
    background-color: #9bd4e2;
    border-radius: 10px;
}

.image-container {
    display: flex;
    justify-content: flex-start;
  }
  
.image-container img {
    width: 20%;
    height: auto;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

.sub-header {
    color: #3691E1;
    margin-bottom: 10px;
    font-weight: bold;
}

.apply-list {
    margin-bottom: 10px;
    list-style-type: disc;
    padding-left: 20px;
}

.prize-link {
    color: $link
}

.apply-button {
    background-color: #3691E1
}
