.con-carousel-image{

    &__container{
        max-height: 600px;
        display: flex;
    }

    &__src{
        object-fit:contain;
        padding: 2%;
    }
    
}

.con-carousel-image__src {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

.con-sponsor-wrapper {
    display: grid;
    place-items: center;
    height: 100%; 
}
  
.Carousel {
    display: inline-block;
}

.con-sponsor-wrapper .carousel .control-dots {
    position: static;
    padding-top: 10px; 
  }

.carousel .control-dots .dot {
    background: #5e9ed6;
}

.carousel .control-arrow {
    position:inherit;
    height: 80%;
    display: none;
  }
