.con-logo {
  text-align: center;
  width: 200px;
  margin-left: -10px;
  display: block;
}

.con-text__align {
  text-align: center;
  padding: 20px;
}

.con-text {
  display: inline-block;
  text-align: left;
}

.con-save-date-image {
  padding-bottom: 20px;
}

.con-calling-speakers-card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;

  &__container {
    border-radius: 10px;
    margin-bottom: 20px;
    width: 100%;
  }
}

.con-link-button {
  background-color: $button-background;
  color: $white;
  border: none;
  cursor: pointer;
  font: inherit;
  display: inline-block;
  border-radius: 12px;
  width: 20%;
  text-align: center;
  padding: 10px;
  margin-left: 40%;
  margin-right: 40%;
}

@media screen and (max-width: 500px) {
  .con-link-button {
    width: 50%;
    margin-left: 25%;
    margin-right: auto;
  }
}

.deadline-subtitle {
  color: $button-background;
  text-align: center;
  margin-top: 10px;
}